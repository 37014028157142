import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import CardRow from 'shared/components/molecules/CardRow';
import Column from 'shared/components/atoms/Column';
import { useSelector } from 'react-redux';
import { getSelectedShadowNNIReference } from 'Quotes/QuoteBuilder/utils/getSelectedNNIReference';
import { INewNNIRequest, IOrderLocation, ISiteConfig } from 'Order/types/location';
import { ShadowVLANIdFieldSummary, VLANIdFieldSummary } from 'Order/OrderBuilder/shared/components/SummaryTextHelper';
import {
  isNewNNI as selectIsNewNNI,
  isNewShadowNNI as selectIsNewShadowNNI,
  selectCurrentMeta,
  selectNNILabel,
  selectQuote,
  selectSelectedPrice,
} from 'Quotes/selectors';
import { NEW_NNI_DESCRIPTION, NEW_NNI_TITLE } from '../../messages';
import { isValidVLANIdField } from 'Order/OrderBuilder/shared/utils/validation';
import { findNewNNIRequestByPopId } from '../Edit/utils/nniRequest';
import { Feature } from 'FeatureFlags/types';
import { featureFlag } from 'FeatureFlags/utils/hasFeatureEnabled';
import { objectIsEmpty } from 'shared/utils/objectHelper';
import { NewNNIRequestSummary } from './NewNNIRequestSummary';
import { NNISummaryFields } from './NNISummaryFields';
import { ShadowNNISummaryFields } from './ShadowNNISummaryFields ';
import { useSecondaryCircuits } from 'shared/components/molecules/SecondaryCircuits/data/useSecondaryCircuits';
import { isP2NNI } from 'shared/components/molecules/SecondaryCircuits/SecondaryCircuits';
import { getSecondaryNNILabel } from 'shared/utils/addresses/helperFunctions';

interface INNISummaryProps {
  className?: string;
  location: IOrderLocation;
  theme: {
    colours: {
      grey50: string;
    };
  };
  vlanPopId?: string | null;
  shadowVlanPopId?: string | null;
  customerName?: string | null;
}

export const useNewNNIRequests = (
  isNewNNI: boolean,
  vlanPopId: string | null | undefined,
  newNNIRequests: INewNNIRequest[]
) => {
  const isNewNNIRequestAvailable = !!(isNewNNI && vlanPopId && featureFlag.isEnabled(Feature.newNNIRequests));
  const newNNIRequest = findNewNNIRequestByPopId(vlanPopId, newNNIRequests);
  const hasNNIRequest = !objectIsEmpty(newNNIRequest);
  return { isNewNNI, newNNIRequest, hasNNIRequest, isNewNNIRequestAvailable };
};

function NNISummaryDetails(props: {
  nniReference: string;
  siteConfig: ISiteConfig;
  primaryNNIRequest: {
    isNewNNIRequestAvailable: boolean;
    isNewNNI: boolean;
    hasNNIRequest: boolean;
    newNNIRequest: INewNNIRequest;
  };
  customerName: string | null | undefined;
  location: IOrderLocation;
  shadowReference?: string | null;
  shadowNNIRequest?: {
    isNewNNIRequestAvailable: boolean;
    isNewNNI: boolean;
    hasNNIRequest: boolean;
    newNNIRequest: INewNNIRequest;
  };
}) {
  return (
    <div className="row">
      <Column defaultWidth={6}>
        <div className="faint">{props.nniReference}</div>
        <div>
          <VLANIdFieldSummary text={props.siteConfig.vlanId || ''} prompt={isValidVLANIdField.errorMessage} />
        </div>

        {props.primaryNNIRequest.isNewNNIRequestAvailable && props.primaryNNIRequest.hasNNIRequest ? (
          <>
            <strong>New NNI request</strong>
            <NewNNIRequestSummary
              customerName={props.customerName}
              newNNIRequest={props.primaryNNIRequest.newNNIRequest}
            />
          </>
        ) : (
          props.primaryNNIRequest.isNewNNI && (
            <NNISummaryFields siteConfig={props.siteConfig} />
          )          
        )}
      </Column>

      {props.shadowReference && (
        <Column defaultWidth={6}>
          <div className="faint">{props.shadowReference}</div>
          <div>
            <ShadowVLANIdFieldSummary text={props.siteConfig.shadowVLANId || ''} />
          </div>
          {props.shadowNNIRequest?.isNewNNIRequestAvailable ? (
            props.shadowNNIRequest?.hasNNIRequest ? (
              <>
                <strong>New shadow NNI request</strong>
                <NewNNIRequestSummary
                  customerName={props.customerName}
                  newNNIRequest={props.shadowNNIRequest.newNNIRequest}
                />
              </>
            ) : (
              <ShadowNNISummaryFields siteConfig={props.siteConfig} />
            )
          ) : (
            props.shadowNNIRequest?.isNewNNI && (
              <ShadowNNISummaryFields siteConfig={props.siteConfig} />
            )
          )}
        </Column>
      )}
    </div>
  );
}

function NewNNIAlert(props: { hasNewNNI: boolean }) {
  return (
    <div className="row">
      <Column smWidth={12} mdWidth={12} lgWidth={9}>
        {props.hasNewNNI && (
          <div className="mt-2">
            <div className="text-danger font-weight-bold">{NEW_NNI_TITLE}</div>
            <p>{NEW_NNI_DESCRIPTION}</p>
          </div>
        )}
      </Column>
    </div>
  );
}

const NNISummary: FunctionComponent<React.PropsWithChildren<INNISummaryProps>> = (props) => {
  return (
    <>
      <PrimaryNNISummary {...props} />
      <SecondaryNNISummary {...props} />
    </>
  );
};
const PrimaryNNISummary: FunctionComponent<React.PropsWithChildren<INNISummaryProps>> = ({
  className,
  location,
  vlanPopId,
  shadowVlanPopId,
  customerName,
}) => {
  const quoteMeta = useSelector(selectCurrentMeta);
  const nniReference = useSelector(selectNNILabel);
  const shadowReference = getSelectedShadowNNIReference(quoteMeta);
  const isNewNNI = useSelector(selectIsNewNNI);
  const isNewShadowNNI = useSelector(selectIsNewShadowNNI);

  const siteConfig = location.siteConfig;
  const primaryNNIRequest = useNewNNIRequests(isNewNNI, vlanPopId, siteConfig.new_nni_requests);
  const shadowNNIRequest = useNewNNIRequests(isNewShadowNNI, shadowVlanPopId, siteConfig.new_nni_requests);

  const hasNewNNI = primaryNNIRequest.isNewNNI || isNewShadowNNI;
  return (
    <CardRow className={`${className} mb-4`} title="NNI Summary" subtitle="Primary Circuit">
      <NNISummaryDetails
        nniReference={nniReference}
        siteConfig={siteConfig}
        primaryNNIRequest={primaryNNIRequest}
        customerName={customerName}
        location={location}
        shadowReference={shadowReference}
        shadowNNIRequest={shadowNNIRequest}
      />

      <NewNNIAlert hasNewNNI={hasNewNNI} />
    </CardRow>
  );
};

const SecondaryNNISummary: FunctionComponent<React.PropsWithChildren<INNISummaryProps>> = ({
  className,
  location,
  vlanPopId,
  customerName,
}) => {
  const quote = useSelector(selectQuote);
  const price = useSelector(selectSelectedPrice);
  const secondaryCircuits = useSecondaryCircuits(price);
  const circuitData = secondaryCircuits.circuitData;
  const nniData = circuitData && isP2NNI(circuitData) ? circuitData?.aEnd : undefined;

  const secondaryNniLabel = getSecondaryNNILabel(quote, price);

  const isNewNNI = !nniData?.selected?.selectedId;
 
  const siteConfig = location.secondarySiteConfig;

  if (!siteConfig || !nniData?.selected) return null;

  const primaryNNIRequest = useNewNNIRequests(isNewNNI, vlanPopId, siteConfig.new_nni_requests);
  return (
    <CardRow className={`${className} mb-4`} title="NNI Summary" subtitle="Secondary Circuit">
      <NNISummaryDetails
        nniReference={secondaryNniLabel}
        siteConfig={siteConfig}
        primaryNNIRequest={primaryNNIRequest}
        customerName={customerName}
        location={location}
      />

      <NewNNIAlert hasNewNNI={isNewNNI} />
    </CardRow>
  );
};

const StyledNNISummary = styled(NNISummary)`
  .faint {
    color: ${(props) => props.theme.colours.grey50};
  }
`;

export default StyledNNISummary;