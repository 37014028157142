import { IPriceData, IQuote } from 'Quotes/types/store';
import { IMeta } from 'Quotes/types/quoteRecordAttributesBase';
import { ISecondarySiteConfig, ISiteConfig } from 'Order/types/location';
import { NNISiteConfig } from 'Order/OrderBuilder/Locations/shared/components/Edit/NNISiteConfig';
import React from 'react';
import { siteConfigProps } from 'Order/OrderBuilder/Locations/shared/components/Edit/index';
import { NNIData } from 'shared/components/molecules/SecondaryCircuits/data/useP2NNICircuitData';
import { getSecondaryNNILabel } from 'shared/utils/addresses/helperFunctions';

export function DualCircuitNNISiteConfig(props: {
  quote: IQuote;
  quoteMeta: IMeta;
  identifierPrefix: string;
  newNNI: boolean | undefined;
  nniLabel: string | undefined;
  selectedPrice: IPriceData;
  updateSiteConfig: (siteConfig: ISiteConfig) => void;
  nniData: NNIData;
  dataCentre: any;
  updateSecondarySiteConfig: (secondarySiteConfig: ISecondarySiteConfig) => void;
  siteConfig: ISiteConfig;
  secondarySiteConfig: ISecondarySiteConfig;
}) {
  const secondaryNNILabel = getSecondaryNNILabel(props.quote, props.selectedPrice);
  return (
    <>
      <NNISiteConfig
        circuitType="Primary Circuit"
        customerName={props.quote.customerName}
        dataCentre={props.quoteMeta.nni_label}
        defaultNNIRequestContactEmail={props.quote.createdBy}
        identifierPrefix={props.identifierPrefix}
        isNewNNI={props.newNNI}
        nniLabel={props.nniLabel}
        popId={props.selectedPrice.a_end_p_o_p_id}
        quote={props.quote}
        quoteMeta={props.quoteMeta}
        siteConfig={props.siteConfig}
        siteConfigProps={siteConfigProps(props.quote.productType)}
        updateSiteConfig={props.updateSiteConfig}
      />
      <NNISiteConfig
        circuitType="Secondary Circuit"
        identifierPrefix={props.identifierPrefix}
        siteConfig={props.secondarySiteConfig}
        siteConfigProps={siteConfigProps(props.quote.productType)}
        nniLabel={secondaryNNILabel}
        isNewNNI={!props.nniData.selected?.selectedId}
        updateSiteConfig={props.updateSecondarySiteConfig}
        customerName={props.quote.customerName}
        dataCentre={props.quoteMeta.nni_label}
        defaultNNIRequestContactEmail={props.quote.createdBy}
        popId={props.dataCentre?.id ?? ''}
        quote={props.quote}
        quoteMeta={props.quoteMeta}
      />
    </>
  );
}